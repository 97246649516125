import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Text } from 'grommet';

import StickyCartHeader from './StickyCartHeader';
import discountSavingsMessage from '../../lib/cart/discountSavingsMessage';
import useCart from './useCart';
import useMobile from '../useMobile';
import selectReferralCode from '../../state/industry/selectReferralCode';
import selectMemberName from '../../state/industry/selectMemberName';

const CartDiscountHeader = ({ onClose }) => {
  const isMobile = useMobile();
  const {
    orderDiscounts,
    savedOnPaint,
    savedOnSupplies,
    samplesOnly,
    lineItems,
  } = useCart();
  const memberName = useSelector(selectMemberName);
  const industryReferralCode = useSelector(selectReferralCode);
  const discountCode = useSelector(selectReferralCode);

  const suppliesDiscountText = discountSavingsMessage({
    orderDiscounts,
    discountCode,
    savedOnPaint,
    savedOnSupplies,
    industryReferralCode,
    industryMemberName: memberName,
    lineItems,
  });

  const suppliesDiscountSubtext = samplesOnly
    ? 'Get a head start on prepping your space, while choosing your colors'
    : isMobile
    ? 'You’re going to need the below supplies'
    : `Based on what's in your cart, you're going to need the
below supplies.`;

  return (
    <StickyCartHeader onClose={onClose}>
      <Text size="small" color="white" weight={500}>
        {suppliesDiscountText}
      </Text>
      <Text size="small" color="white" weight={400}>
        {suppliesDiscountSubtext}
      </Text>
    </StickyCartHeader>
  );
};

CartDiscountHeader.propTypes = {
  onClose: PropTypes.func,
};

export default memo(CartDiscountHeader);
